var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('validation-observer',{ref:"userObserver"},[_c('b-sidebar',{staticClass:"sidebar-form",attrs:{"id":"addEditGroup","bg-variant":"white","right":"","backdrop":"","shadow":""},on:{"hidden":_vm.sidebarToggle},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('div',{staticClass:"form-button d-flex my-2"},[_c('button',{staticClass:"btn side-bar-btn",attrs:{"type":"submit","disabled":_vm.btnLoader},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[(_vm.btnLoader)?_c('b-spinner',{staticClass:"spinner",attrs:{"small":""}}):_vm._e(),_vm._v(" "+_vm._s(_vm.id ? "Update" : "Save")+" ")],1),_c('button',{staticClass:"btn side-bar-btn",on:{"click":function($event){return _vm.clearData()}}},[_vm._v(" Clear ")])])]},proxy:true}])},[_c('h3',{staticClass:"form-title"},[_vm._v(" "+_vm._s(_vm.id ? "Edit" : "Add")+" employee ")]),_c('div',{staticClass:"line user-underline"}),_c('b-form-group',{attrs:{"label":"First Name","label-for":"First name"}},[_c('validation-provider',{attrs:{"name":"First name","rules":"required|max:40"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name","state":errors.length > 0 ? false : null,"placeholder":"First Name","name":"first_name","disabled":_vm.isDisable},model:{value:(_vm.name),callback:function ($$v) {_vm.name=$$v},expression:"name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Last Name","label-for":"Last name"}},[_c('validation-provider',{attrs:{"name":"Last name","rules":"required|alpha_spaces|max:40"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"lastname","state":errors.length > 0 ? false : null,"placeholder":"Last Name","name":"last_name","disabled":_vm.isDisable},model:{value:(_vm.lastName),callback:function ($$v) {_vm.lastName=$$v},expression:"lastName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Email","label-for":"email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":{
            required: true,
            regex: /^[a-zA-Z0-9.]*$/,
          }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",attrs:{"append":"@zignuts.com"}},[_c('b-form-input',{attrs:{"id":"email","placeholder":"Email","name":"email","disabled":_vm.isDisable},model:{value:(_vm.email),callback:function ($$v) {_vm.email=$$v},expression:"email"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{attrs:{"label":"Contact no","label-for":"phone"}},[_c('validation-provider',{attrs:{"name":"Contact no."},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"phone","state":errors.length > 0 ? false : null,"placeholder":"Contact no","name":"phone","type":"number","disabled":_vm.isDisable},model:{value:(_vm.phone),callback:function ($$v) {_vm.phone=$$v},expression:"phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"role-select",attrs:{"label":"role","label-for":"role"}},[_c('validation-provider',{attrs:{"name":"Role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-select',{staticClass:"select-size-lg",attrs:{"item-value":"name","hide-details":"","name":"role","label":"name","options":_vm.roles,"reduce":function (role) { return role.code; },"placeholder":"Select Role"},model:{value:(_vm.role),callback:function ($$v) {_vm.role=$$v},expression:"role"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"label":"actual rate","label-for":"actualrate"}},[_c('validation-provider',{attrs:{"name":"Actual rate","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",attrs:{"id":"actualrate","append":"USD"}},[_c('b-form-input',{attrs:{"placeholder":"Actual Rate","type":"number","name":"actual_rate","disabled":_vm.isDisable},model:{value:(_vm.actual_rate),callback:function ($$v) {_vm.actual_rate=$$v},expression:"actual_rate"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"label":"billable rate","label-for":"billablerate"}},[_c('validation-provider',{attrs:{"name":"Billable rate","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-input-group',{staticClass:"input-group-merge",attrs:{"id":"billablerate","append":"USD"}},[_c('b-form-input',{attrs:{"name":"billable_rate","type":"number","placeholder":"Billable Rate","disabled":_vm.isDisable},model:{value:(_vm.billable_rate),callback:function ($$v) {_vm.billable_rate=$$v},expression:"billable_rate"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"role-select",attrs:{"label":"reporting to","label-for":"reportingto"}},[_c('validation-provider',{attrs:{"name":"Reporting to","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-select',{staticClass:"select-size-lg",attrs:{"hide-details":"","name":"reporting_to","label":"name","options":_vm.users,"reduce":function (user) { return user.id; },"placeholder":"Select Reporting To","disabled":_vm.isDisable},model:{value:(_vm.reporting_to),callback:function ($$v) {_vm.reporting_to=$$v},expression:"reporting_to"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"role-select",attrs:{"label":"managed by","label-for":"manage_by"}},[_c('validation-provider',{attrs:{"name":"Managed by","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('v-select',{staticClass:"select-size-lg",attrs:{"hide-details":"","name":"manage_by","label":"name","options":_vm.users,"reduce":function (user) { return user.id; },"placeholder":"Select Managed by","disabled":_vm.isDisable},model:{value:(_vm.manage_by),callback:function ($$v) {_vm.manage_by=$$v},expression:"manage_by"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"d-flex justify-content-between",attrs:{"label":"Is Resource Managing","label-for":"manage_by"}},[_c('b-form-checkbox',{staticClass:"custom-control-success d-flex justify-content-between",attrs:{"name":"check-button","switch":""},model:{value:(_vm.is_resource_managing),callback:function ($$v) {_vm.is_resource_managing=$$v},expression:"is_resource_managing"}})],1),_c('validation-observer',{ref:"primerySkillObserver"},[_c('div',{staticClass:"primary-skill-section"},[_c('b-form-group',{staticClass:"hello",attrs:{"label":"Primary skill","label-for":"primary_skill_id"}},[_c('validation-provider',{attrs:{"name":"Primary Skill"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [(_vm.primerySkillShow == false)?_c('v-select',{staticClass:"select-size-lg primary-skill-select",attrs:{"hide-details":"","multiple":"","name":"primary_skill_id","label":"name","options":_vm.primarySkills,"reduce":function (skill) { return skill.id; },"placeholder":"Select skill","close-on-select":false,"disabled":_vm.isDisable},model:{value:(_vm.primarySkillID),callback:function ($$v) {_vm.primarySkillID=$$v},expression:"primarySkillID"}}):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.primerySkillShow == true)?_c('b-form-group',[_c('validation-provider',{attrs:{"name":"Primary Skill","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"primaryskill","state":errors.length > 0 ? false : null,"placeholder":"Primary skill","name":"primaryskill","disabled":_vm.isDisable},model:{value:(_vm.primarySkillName),callback:function ($$v) {_vm.primarySkillName=$$v},expression:"primarySkillName"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1126836546)})],1):_vm._e(),_c('div',{staticClass:"form-button-new d-flex my-2"},[(_vm.primerySkillShow == true)?_c('button',{staticClass:"btn side-bar-btn",attrs:{"type":"submit","disabled":_vm.btnLoader},on:{"click":function($event){$event.preventDefault();return _vm.primerySkillForm.apply(null, arguments)}}},[(_vm.btnLoader)?_c('b-spinner',{staticClass:"spinner",attrs:{"small":""}}):_vm._e(),_vm._v(" "+_vm._s("Add Skills")+" ")],1):_vm._e()])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }