<template>
  <div>
    <validation-observer ref="userObserver">
      <b-sidebar
        id="addEditGroup"
        bg-variant="white"
        right
        backdrop
        shadow
        class="sidebar-form"
        @hidden="sidebarToggle"
      >
        <!-- user form start -->

        <h3 class="form-title">
          {{ id ? "Edit" : "Add" }} employee
        </h3>
        <div class="line user-underline" />
        <!-- First Name input -->
        <b-form-group
          label="First Name"
          label-for="First name"
        >
          <validation-provider
            #default="{ errors }"
            name="First name"
            rules="required|max:40"
          >
            <b-form-input
              id="name"
              v-model="name"
              :state="errors.length > 0 ? false : null"
              placeholder="First Name"
              name="first_name"
              :disabled="isDisable"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Last Name input -->
        <b-form-group
          label="Last Name"
          label-for="Last name"
        >
          <validation-provider
            #default="{ errors }"
            name="Last name"
            rules="required|alpha_spaces|max:40"
          >
            <b-form-input
              id="lastname"
              v-model="lastName"
              :state="errors.length > 0 ? false : null"
              placeholder="Last Name"
              name="last_name"
              :disabled="isDisable"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Email input -->
        <b-form-group
          label="Email"
          label-for="email"
        >
          <validation-provider
            #default="{ errors }"
            name="Email"
            :rules="{
              required: true,
              regex: /^[a-zA-Z0-9.]*$/,
            }"
          >
            <b-input-group
              append="@zignuts.com"
              class="input-group-merge"
            >
              <b-form-input
                id="email"
                v-model="email"
                placeholder="Email"
                name="email"
                :disabled="isDisable"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!-- Phone Input -->
        <b-form-group
          label="Contact no"
          label-for="phone"
        >
          <validation-provider
            #default="{ errors }"
            name="Contact no."
          >
            <b-form-input
              id="phone"
              v-model="phone"
              :state="errors.length > 0 ? false : null"
              placeholder="Contact no"
              name="phone"
              type="number"
              :disabled="isDisable"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!-- Role Input Select -->
        <b-form-group
          label="role"
          label-for="role"
          class="role-select"
        >
          <validation-provider
            #default="{ errors }"
            name="Role"
            rules="required"
          >
            <v-select
              v-model="role"
              item-value="name"
              hide-details
              name="role"
              label="name"
              :options="roles"
              :reduce="(role) => role.code"
              class="select-size-lg"
              placeholder="Select Role"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!-- Actual rate Input Select -->
        <b-form-group
          v-show="false"
          label="actual rate"
          label-for="actualrate"
        >
          <!-- :rules="{ required: true,regex: /^\d{0,8}(\.\d{2})?$/ }" -->
          <validation-provider
            #default="{ errors }"
            name="Actual rate"
            rules=""
          >
            <b-input-group
              id="actualrate"
              append="USD"
              class="input-group-merge"
            >
              <b-form-input
                v-model="actual_rate"
                placeholder="Actual Rate"
                type="number"
                name="actual_rate"
                :disabled="isDisable"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!-- Billable rate Input Select -->
        <b-form-group
          v-show="false"
          label="billable rate"
          label-for="billablerate"
        >
          <!---:rules="{ required: true,regex: /^\d{0,8}(\.\d{2})?$/ }"-->
          <validation-provider
            #default="{ errors }"
            name="Billable rate"
            rules=""
          >
            <b-input-group
              id="billablerate"
              append="USD"
              class="input-group-merge"
            >
              <b-form-input
                v-model="billable_rate"
                name="billable_rate"
                type="number"
                placeholder="Billable Rate"
                :disabled="isDisable"
              />
            </b-input-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <!-- Reporting to Input Select -->
        <b-form-group
          label="reporting to"
          label-for="reportingto"
          class="role-select"
        >
          <validation-provider
            #default="{ errors }"
            name="Reporting to"
            rules="required"
          >
            <v-select
              v-model="reporting_to"
              hide-details
              name="reporting_to"
              label="name"
              :options="users"
              :reduce="(user) => user.id"
              class="select-size-lg"
              placeholder="Select Reporting To"
              :disabled="isDisable"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!-- Manage_by to Input Select -->
        <b-form-group
          label="managed by"
          label-for="manage_by"
          class="role-select"
        >
          <validation-provider
            #default="{ errors }"
            name="Managed by"
            rules="required"
          >
            <v-select
              v-model="manage_by"
              hide-details
              name="manage_by"
              label="name"
              :options="users"
              :reduce="(user) => user.id"
              class="select-size-lg"
              placeholder="Select Managed by"
              :disabled="isDisable"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <!--Resource plan-->
        <b-form-group
          label="Is Resource Managing"
          class="d-flex justify-content-between"
          label-for="manage_by"
        >
          <b-form-checkbox
            v-model="is_resource_managing"
            class="custom-control-success d-flex justify-content-between"
            name="check-button"
            switch
          />
        </b-form-group>

        <!-- User Primary skills -->
        <validation-observer ref="primerySkillObserver">
          <div class="primary-skill-section">

            <b-form-group
              label="Primary skill"
              label-for="primary_skill_id"
              class="hello"
            >
              <validation-provider
                #default="{ errors }"
                name="Primary Skill"
              >
                <v-select
                  v-model="primarySkillID"
                  v-if="primerySkillShow == false"
                  hide-details
                  multiple
                  name="primary_skill_id"
                  label="name"
                  :options="primarySkills"
                  :reduce="(skill) => skill.id"
                  class="select-size-lg primary-skill-select"
                  placeholder="Select skill"
                  :close-on-select="false"
                  :disabled="isDisable"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>

          </div>

          <!-- primery skill input -->
          <b-form-group v-if="primerySkillShow == true">
            <validation-provider
              #default="{ errors }"
              name="Primary Skill"
              rules="required"
            >
              <b-form-input
                id="primaryskill"
                v-model="primarySkillName"
                :state="errors.length > 0 ? false : null"
                placeholder="Primary skill"
                name="primaryskill"
                :disabled="isDisable"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <div class="form-button-new d-flex my-2">
            <button
              v-if="primerySkillShow == true"
              class="btn side-bar-btn"
              type="submit"
              :disabled="btnLoader"
              @click.prevent="primerySkillForm"
            >
              <b-spinner
                v-if="btnLoader"
                small
                class="spinner"
              />
              {{ "Add Skills" }}
            </button>
          </div>
        </validation-observer>

        <!-- form footer -->
        <template #footer>
          <div class="form-button d-flex my-2">
            <button
              class="btn side-bar-btn"
              type="submit"
              :disabled="btnLoader"
              @click.prevent="validationForm"
            >
              <b-spinner
                v-if="btnLoader"
                small
                class="spinner"
              />
              {{ id ? "Update" : "Save" }}
            </button>
            <button
              class="btn side-bar-btn"
              @click="clearData()"
            >
              Clear
            </button>
          </div>
        </template>
      </b-sidebar>
    </validation-observer>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import vSelect from 'vue-select'
import { eventBus } from '@/main'
export default {
  name: 'ClientTableForm',
  components: {
    vSelect,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      id: null,
      name: null,
      email: null,
      phone: null,
      role: 'EMP',
      lastName: null,
      actual_rate: null,
      billable_rate: null,
      reporting_to: null,
      manage_by: null,
      primerySkillShow: false,
      primarySkillName: null,
      users: [],
      roles: [
        { code: 'SA', name: 'Super Admin' },
        { code: 'MN', name: 'Manager' },
        { code: 'HR', name: 'Human Resources' },
        { code: 'EMP', name: 'Employee' },
      ],
      btnLoader: false,
      primarySkills: [],
      primarySkillID: [],
      is_resource_managing: false,
      isDisable: true,
    }
  },
  watch: {},
  mounted() {
    this.getUserData()
    this.getPrimarySkillData()
    eventBus.$on('changedUserStatus', data => {
      if (data) {
        this.getUserData()
      }
    })
    eventBus.$on('edit-user', data => {
      if (data) {
        // console.log('data',data)
        this.id = data.id
        this.name = data.name
        this.lastName = data.last_name
        this.email = data.email
        this.phone = data.phone
        this.role = data.role
        this.actual_rate = data.actual_rate
        this.billable_rate = data.billable_rate
        this.reporting_to = data.reporting_to
        this.manage_by = data.manage_by
        this.$root.$emit('bv::toggle::collapse', 'addEditGroup')
        eventBus.$off('edit-user')
      }
    })
  },
  methods: {
    /**
     * Get primary skill data
     * @returns primarySkills (variable)
     */
    async getPrimarySkillData() {
      const input = {
        is_active: 1,
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/primary-skill',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        this.primarySkills = data.count ? data.primary_skills : []
      }
      this.loading = false
    },
    /**
     * Get users data
     * @returns users for manage_by & Reporting field
     */
    async getUserData() {
      const input = {
        is_active: 1,
        // status: 'joined'
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/user',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        // if (data.count) {
        this.users = data.users.map((user, index) => {
          user.name = `${user.name} ${user.last_name} `
          return user
        })
        // }
        // this.users = [];
      }
      this.loading = false
    },
    /**
     * clear from detail
     */
    sidebarToggle() {
      this.id = null
      this.name = null
      this.lastName = null
      this.email = null
      this.phone = null
      this.role = null
      this.actual_rate = null
      this.billable_rate = null
      this.reporting_to = null
      this.manage_by = null
      this.primarySkillID = null
      this.is_resource_managing = false
      this.$refs.userObserver.reset()
    },
    /**
     * check the form validation
     * @returns if success true then call saveUserDtl Method
     */
    validationForm() {
      this.$refs.userObserver.validate().then(success => {
        if (success) {
          this.saveUserDtl()
        }
      })
    },
    primerySkillForm() {
      this.$refs.primerySkillObserver.validate().then(success => {
        if (success) {
          this.savePrimarySkillDtl()
        }
      })
    },

    async savePrimarySkillDtl() {
      if (!this.primarySkillName) {
        return
      }
      const input = {
        name: this.primarySkillName,
      }
      let response = null
      response = await this.getHTTPPostResponse(
        'admin/master/primary-skill/create',
        input,
        true,
      )
      if (response && response.status == 200) {
        this.getPrimarySkillData()
        this.primerySkillShow = false
        this.primarySkillID.push(response.data.primary_skill.id)
        this.primarySkillName = null
      }
      this.btnLoader = false
    },
    /**
     * Add & Update the UserTableform details & call User listing in Usertable
     * @emits userList
     * @returns User detail add & update
     */
    async saveUserDtl() {
      this.btnLoader = true
      const input = {
        name: this.name,
        last_name: this.lastName,
        email: `${this.email}@zignuts.com`,
        phone: this.phone,
        role: this.role,
        reporting_to: this.reporting_to,
        manage_by: this.manage_by,
        primary_skill_id: this.primarySkillID,
        is_resource_managing: this.is_resource_managing === true ? 1 : 0,
      }
      let response = null

      if (this.id) {
        response = await this.getHTTPPutResponse(
          `admin/master/user/update/${this.id}`,
          input,
          true,
        )
      } else {
        response = await this.getHTTPPostResponse(
          'admin/master/user/create',
          input,
          true,
        )
      }

      if (response && response.status === 200) {
        this.$root.$emit('bv::toggle::collapse', 'addEditGroup')
        this.$emit('userList', true)
        setTimeout(() => {
          this.btnLoader = false
        }, 200)
      }
      this.btnLoader = false
    },
    /**
     * Clear form details
     */
    clearData() {
      this.name = null
      this.lastName = null
      this.email = null
      this.phone = null
      this.role = null
      this.actual_rate = null
      this.billable_rate = null
      this.reporting_to = null
      this.manage_by = null
      this.primarySkillID = []
      this.primarySkillName = null
      this.is_resource_managing = false
      this.$refs.userObserver.reset()
    },
  },
}
</script>

<style>
.role-select .select-size-lg .vs__selected{
  margin: 0px 0px 0 !important;
}
.role-select .vs__dropdown-toggle{
  padding: 9px 10px 6px 10px !important;
}
/* .vs__selected{
  margin-top: 0 !important;
} */
.primary-skill-select .vs__dropdown-toggle {
  padding: 10px !important;
}
.primary-skill-select .vs__dropdown-toggle .vs__selected {
  padding: 3px !important;
  margin-right: 2px !important;
  background-color: #2178fb;
  color: #ffff;
  font-weight: 600;
  margin-bottom: 2px !important;
}
.primary-skill-select .vs__dropdown-toggle .vs__selected .vs__deselect {
  fill: #ffff;
}
</style>
