<template>
  <div>
    <div class="card-filter-section mt-0 d-flex align-items-center justify-content-between">
      <p class="task-timesheet m-0">
        Employee
      </p>
      <div class="role-buttons d-flex flex-wrap align-items-center">
        <!-- add user button -->
        <table-button
          v-show="false"
          v-b-toggle.addEditGroup
          btn-name="add employee"
        />
        <!-- add user button -->
      </div>
      <Help
        v-show="$store.state.app.userManualList"
        code="EMP"
      />
    </div>
    <!-- Filter start -->
    <div class="card-filter-section">
      <div class="d-flex align-items-center flex-wrap justify-content-between">
        <div class="d-flex align-items-center justify-content-start flex-wrap">
          <p class="filter-title">
            Filters:
          </p>

          <div class="">
            <custom-dropdown
              v-model="is_active"
              :options="isActiveList"
              :search-box="false"
              :hide-close="true"
              label="Active"
              icon="EditIcon"
            />
          </div>
          <div class="">
            <div class="">
              <custom-dropdown
                v-model="userStatus"
                :search-box="false"
                :options="statusList"
                :hide-close="true"
                label="Status"
                icon="EditIcon"
              />
            </div>
          </div>
          <div class="">
            <custom-dropdown
              v-model="role"
              :search-box="false"
              :options="roleList"
              label="Role"
              icon="EditIcon"
            />
          </div>

          <div class="">
            <custom-dropdown
              v-model="manage_by"
              :options="manage_by_users"
              label="Managed by"
              icon="EditIcon"
            />
          </div>

          <div class="">
            <custom-dropdown
              v-model="reporting_to"
              :options="reporting_to_users"
              label="Reporting to"
              icon="EditIcon"
            />
          </div>
          <option-drop-down v-model="perPage" />
        </div>

        <div class="d-flex align-items-center">
          <b-input-group class="input-group-merge search-project filter-search-input">
            <b-form-input
              v-model="debouncedSearch"
              placeholder="Search by Employee Name"
            />

            <b-input-group-append is-text>
              <feather-icon
                v-if="debouncedSearch"
                icon="XIcon"
                @click="debouncedSearch = null, search = null"
              />
              <feather-icon icon="SearchIcon" />
            </b-input-group-append>
          </b-input-group>

          <div class="ml-1">
            <button
              class="apply-filter filterRedesign mr-1"
              @click="getUserData()"
            >
              Apply Filter
            </button>
            <button
              class="apply-filter-clear filterRedesign"
              @click="clearFilter()"
            >
              Clear
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Filter End -->

    <div class="user-table table">
      <table-loader
        v-if="loader"
        :fields="fields_userList"
      />
      <b-row v-else>
        <b-col cols="12">
          <!-- data table start from here -->
          <b-table
            id="table"
            responsive
            :items="items"
            :fields="fields_userList"
            show-empty
            class="scrollable-element user-table-items approvel-table  tableDesigns mastertblColor"
          >
            <template #cell(name)="data">
              <div class="media">
                <div class="media-aside align-items-center">
                  <b-avatar
                    size="md"
                    :src="data.item.picture"
                    :text="data.item.full_name | avatarText"
                    :variant="randomVariant(1, items.length)"
                  />
                </div>
                <div class="media-body">
                  <p
                    class="d-block user-emp-name"
                    :style="{ color: '#' + data.item.color }"
                  >
                    {{ data.item.name }} {{ data.item.last_name }}
                    <img
                      v-if="data.item.status == 'invited'"
                      v-b-tooltip.hover.top="'Pending'"
                      src="../../assets/images/icons/Pending.svg"
                    >
                  </p>
                  <small class="user-emp-status">{{
                    data.item.role === "SA"
                      ? "Super Admin"
                      : data.item.role === "EMP"
                        ? "Employee"
                        : data.item.role === "HR"
                          ? "Human resources"
                          : "Manager"
                  }}</small>
                </div>
              </div>
            </template>
            <template #cell(primary_skills)="data">
              <span
                v-for="(skill, sindex) in data.item.primary"
                :key="sindex"
                class="badge text-capitalize badge-light-success badge-pill"
                style="margin-right: 5px"
              >
                {{ skill.name }}
              </span>
            </template>
            <template #cell(secondary_skill)="data">
              <span
                v-for="(skill, sindex) in data.item.secondary"
                :key="sindex"
                class="badge text-capitalize badge-light-success badge-pill"
                style="margin-right: 5px"
              >
                {{ skill.name }}
              </span>
            </template>
            <template #cell(is_active_date)="data">
              {{ data.item.inactive_date }}
            </template>
            <template #cell(status)="data">
              <b-badge
                v-if="data.item.value === 'rejected'"
                variant="light-danger"
              >
                Rejected
              </b-badge>
              <b-badge
                v-else-if="data.value === 'invited'"
                variant="light-success"
              >
                Invited
              </b-badge>
              <b-badge
                v-else-if="data.value === 'joined'"
                variant="light-info"
              >
                Joined
              </b-badge>
            </template>

            <template #cell(action)="data">
              <span class="d-flex">
                <feather-icon
                  v-b-tooltip.hover.top="'Edit'"
                  icon="EditIcon"
                  class="mr-1 text-dark"
                  size="18"
                  @click="edit(data.item)"
                />
                <feather-icon
                  v-if="data.item.status == 'invited'"
                  v-b-tooltip.hover.top="'Send'"
                  icon="SendIcon"
                  class="text-primary cursor-pointer"
                  size="18"
                  @click="sendInvitationMail(data.item.id)"
                />
              </span>
            </template>
          </b-table>
          <!-- data table end -->

          <!-- data table complete -->
          <img
            v-if="totalCount == 0"
            src="@/assets/images/nodatafound/nodata.svg"
            alt="no-data"
            class="no-data-img"
          >
        </b-col>

      </b-row>
      <!-- pagination section -->
      <Pagination
        v-if="totalCount > 0 && !loader"
        :current-page="currentPage"
        :per-page="perPage"
        :total-count="totalCount"
        @currentPage="currentPage = $event"
      />
      <!-- pagination section end -->
      <!-- add/edit user form start -->
      <user-table-from
        ref="addEditUserForm"
        @userList="getUserData"
      />
    </div>
  </div>
</template>

<script>
import { eventBus } from '@/main'
import customDropdown from '@/components/dropdown/customDropdown.vue'
import debounce from 'lodash/debounce'
import TableButton from '../../@core/components/buttons/TableButton.vue'
import UserTableFrom from '../../@core/components/table-from/UserTablefrom.vue'

export default {
  name: 'UserTable',
  components: {
    TableButton,
    UserTableFrom,
    customDropdown,
  },
  data() {
    return {
      // in filter  used
      status: [],
      user: null,
      search: null,
      role: null,
      userStatus: 'all',
      reporting_to_users: [],
      manage_by_users: [],
      userRole: null,
      reporting_to: null,
      manage_by: null,
      totalCount: 0,
      currentPage: 1,
      perPage: 10,
      loader: true,
      sortDesc: false,
      is_active: 'active',
      items: [],
    }
  },
  computed: {
    fields_userList() {
      const commonFields = [
        {
          key: 'emp_id',
          label: 'Employee Id',
          sortable: true,
          thStyle: { width: '100px' },
          tdStyle: { width: '100px' },
        },
        {
          key: 'name',
          label: 'Name',
          sortable: true,
          thStyle: { width: '300px' },
          tdStyle: { width: '300px' },
        },
        {
          key: 'email',
          label: 'Email',
          sortable: true,
          tdClass: 'email-user',
        },
        { key: 'phone', label: 'Phone', sortable: true },
        { key: 'users.full_name', label: 'Reporting To', sortable: true },
        { key: 'manage_by_user.full_name', label: 'Managed By', sortable: true },
        {
          key: 'primary_skills',
          label: 'Primary Skill(s)',
          sortable: false,
          thStyle: { width: '350px' },
          tdStyle: { width: '350px' },
        },
        {
          key: 'secondary_skill',
          label: 'Secondary Skill(s)',
          sortable: false,
          thStyle: { width: '350px' },
          tdStyle: { width: '350px' },
        },
      ]

      const specificFields = this.is_active === 'active' ? [] : [
        {
          key: 'is_active_date',
          label: 'In Active Date',
          thClass: 'user-img',
          sortable: false,
        },
      ]

      return [...commonFields, ...specificFields, {
        key: 'action',
        label: 'Action',
        thClass: 'user-img',
        sortable: false,
      }]
    },
    statusList() {
      return [
        { label: 'All', value: 'all' },
        { label: 'invited', value: 'invited' },
        { label: 'joined', value: 'joined' },
      ]
    },
    roleList() {
      return [
        { label: 'Employee', value: 'EMP' },
        { label: 'Human Resources', value: 'HR' },
        { label: 'Manager', value: 'MN' },
        { label: 'Super Admin', value: 'SA' },
      ]
    },
    debouncedSearch: {
      get() {
        return this.search
      },
      set: debounce(function (value) {
        this.search = value
        this.getUserData()
      }, 750),
    },
  },
  watch: {

    perPage() {
      if (this.currentPage === 1) {
        this.getUserData()
      } else {
        this.currentPage = 1
      }
    },
    /**
     *  on page number change Call getUserData()
     */
    currentPage: {
      handler(value) {
        if (value) {
          this.getUserData()
        }
      },
    },
  },
  mounted() {
    eventBus.$on('user', data => {
      if (data) {
        this.search = data.search
        this.is_active = data.is_active
        this.userRole = data.role
        this.userStatus = data.userstatus || 'all'
        this.manage_by = data.manage_by
        this.reporting_to = data.reporting_to
        this.getUserData()
        this.$root.$emit('bv::toggle::collapse', 'search')
      }
    })
    this.getUserData()
    this.filterFunctionCallFromMixin()
  },
  methods: {
    async filterFunctionCallFromMixin() {
      this.manage_by_users = await this.manageByUserList()
      this.reporting_to_users = await this.reportingtoUserList()
    },
    /**
     *  Get User List
     * @method POST
     * @returns items (variable)(user list)
     */
    async getUserData() {
      this.loader = true

      const input = {
        reporting_to: this.reporting_to,
        manage_by: this.manage_by,
        page: this.currentPage,
        per_page: this.perPage,
        sort_field: 'name',
        sort_order: this.sortDesc ? 'desc' : 'asc',
        is_active: this.is_active && this.is_active !== 'all_data'
          ? this.is_active === 'active'
          : '',
        role: this.role,
        status: this.userStatus === 'all' ? '' : this.userStatus,
        search: this.search,
      }
      const response = await this.getHTTPPostResponse(
        'admin/master/user/list',
        input,
        false,
      )
      if (response && response.data) {
        const { data } = response
        this.items = data.users

        this.totalCount = data.count
      }
      this.loader = false
    },
    /**
     *  For Edit project-user pass data of project-user to project-user table form
     * @refs addEditUserForm.id ,name, email .phone. role, actual_rate , billable_rate ,reporting_to ,manage_by, primarySkillID
     */
    edit(data) {
      console.log('data.is_resource_managing', data.is_resource_managing)
      // to emit the event
      this.$refs.addEditUserForm.id = data.id
      this.$refs.addEditUserForm.name = data.name
      this.$refs.addEditUserForm.lastName = data.last_name
      this.$refs.addEditUserForm.email = data.email.replace('@zignuts.com', '')
      this.$refs.addEditUserForm.phone = data.phone
      this.$refs.addEditUserForm.role = data.role
      this.$refs.addEditUserForm.actual_rate = data.actual_rate
      this.$refs.addEditUserForm.billable_rate = data.billable_rate
      this.$refs.addEditUserForm.is_resource_managing = data.is_resource_managing
      setTimeout(() => {
        this.$refs.addEditUserForm.reporting_to = data.reporting_to
        this.$refs.addEditUserForm.manage_by = data.manage_by
      }, 600)

      this.$root.$emit('bv::toggle::collapse', 'addEditGroup')
    },

    async sendInvitationMail(id) {
      this.boxOne = ''
      this.$swal({
        title: 'Are you sure?',
        icon: 'info',
        html: '<p> You want to Send Invitation</p>',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Send',
        confirmButtonAriaLabel: 'Thumbs up, great!',
        cancelButtonAriaLabel: 'Thumbs down',
        customClass: {
          confirmButton: 'btn confirm',
          cancelButton: 'btn cancel ml-1',
        },
        buttonsStyling: false,
      }).then(value => {
        this.boxOne = value
        if (value.isConfirmed === true) {
          const input = {
            user_id: id,
          }
          const response = this.getHTTPPostResponse(
            'admin/master/user/resend-invitation',
            input,
            true,
          )
          if (response) {
            this.getUserData()
          }
        }
      })
    },
    clearFilter() {
      this.status = []
      this.manage_by = null
      this.reporting_to = null
      this.search = null
      this.is_active = 'active'
      this.role = null
      this.userStatus = 'all'
      this.perPage = 10
      this.getUserData()
    },
  },
}
</script>
<style lang="scss" scoped>
@import "../assets/scss/variables/_variables.scss";

.card-filter-section {
  background-color: $white;
  margin: 10px 0px 10px 0px;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  padding: 10px 15px;

  .filter-title {
    color: $logo-color;
    font-size: 2.25 * $rem;
    font-weight: $fw500;
  }

  .selectmember {
    cursor: pointer;
    margin-left: 8px !important;
    background-color: transparent;
    border-color: transparent;
    color: $logo-color;
    display: flex;
    align-items: center;
    position: relative;

    @media (max-width: map-get($mediaSizes , "xl")) {
      margin-left: 18px;
    }

    @media (max-width: map-get($mediaSizes , "lg")) {
      margin-left: 0;
      margin: 7px 0;
    }

    span {
      font-size: 1.75 * $rem;
      font-weight: $fw500;
      text-transform: capitalize;
      line-height: 23px;
    }

    .feather-edit {
      margin-right: 8px;
    }

    .feather-chevron-down {
      margin-left: 8px;
    }

    .badge-pill-filter {
      position: absolute;
      top: -12px;
      right: 13px;
      display: flex;
      height: 20px;
      width: 20px;
      align-items: center;
      justify-content: center;
      padding: 0;
      background-color: rgba(255, 159, 67, 0.7);
    }
  }

  .radio-dropdown {
    position: absolute;
    z-index: 111;
  }

  .apply-filter {
    background-color: $secondary-color;
    border: 1px solid $secondary-color;
    color: $white;
    padding: 6px 11px;
    border-radius: 5px;
    font-size: 2 * $rem;
    font-weight: $fw600;

    @media (max-width: map-get($mediaSizes , "xxl")) {
      font-size: 2 * $rem;
    }

    @media (max-width: map-get($mediaSizes , "xl")) {
      font-size: 1.75 * $rem;
    }

    @media (max-width: map-get($mediaSizes , "lg")) {
      font-size: 1.5 * $rem;
    }
  }

  .apply-filter-clear {
    border: 1px solid $secondary-color;
    color: $secondary-color;
    padding: 6px 20px;
    border-radius: 5px;
    font-size: 2 * $rem;
    font-weight: $fw600;
    background-color: transparent;

    @media (max-width: map-get($mediaSizes , "xxl")) {
      font-size: 2 * $rem;
    }

    @media (max-width: map-get($mediaSizes , "xl")) {
      font-size: 1.75 * $rem;
    }

    @media (max-width: map-get($mediaSizes , "lg")) {
      font-size: 1.5 * $rem;
    }
  }

  .search-project {
    border-radius: 5px;
    color: $dark-gray;

    &:focus-visible {
      outline: $primary-color;
    }

    .form-control {
      border-color: $input-outline;
      padding: 6px 0 6px 20px;
      color: $logo-color !important;
      height: 33px;

      &::placeholder {
        color: $dark-gray;
        font-size: 1.5 * $rem;
        font-weight: $fw500;
      }
    }
  }
}

.filter-search-input {
  min-width: 250px;
  max-width: 250px;
  width: 250px;
}
</style>
